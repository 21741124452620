import React from "react"
import { Carousel } from "react-bootstrap"
import * as style from "./Testimonial.module.css"

const contents = [
    {
        title: "Zahid Un-Nabi",
        message:
            "Tarek is one of the best developers I've worked with. I was his team leader in The Cookups Technology. He is a fast learner and a detail-oriented person. Besides, that is his thought process and punctuality is truly appreciatable. If you are seeking a dynamic-minded developer Tarek is the one. Wishing you the best. Always keep faith in yourself.",
    },
    {
        title: "Toufiqur Rahman",
        message:
            "I love to say lot of appreciations about tarek. but the first thing I must say is, this guy is horribly hard working. He's a good knowledge in programming as well as a good app developer. Recently his dedication about python and django impressed me heavily. there are lot of positiveness about him like leadership ability, team player etc. I know him for last 6 years. as a friend he's 8/10 and as human being 9/10...may be it should be 10/10 but in my perception no one is perfect so I give him 9/10 as human. I guarantee that your journey with him is going to be your best among all.",
    },
    {
        title: "Ahnaf Ahmed Shoumik",
        message:
            "He has a very clear knowledge about android app development . Also has good python skills. I personally know him for years and found that he is very much committed to the work and very disciplined , punctual and trustworthy person . Works very hard in the projects. Has skills in c and c++ programming. Also took part in the programming contests . Highly recommend him for any kind of android app development.",
    },
]
export default function Testimonial() {
    return (
        <>
            <h2>Testimonial</h2>

            <Carousel variant="dark" className={style.container}>
                {contents.map((item, idx) => (
                    <Carousel.Item key={idx} style={{ minHeight: "250px" }}>
                        <svg
                            class="bd-placeholder-img"
                            width="100%"
                            height="100%"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            preserveAspectRatio="xMidYMid slice"
                            focusable="false"
                        ></svg>
                        <Carousel.Caption>
                            <h5>{item.title}</h5>
                            <p>{item.message}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </>
    )
}
